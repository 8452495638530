<template lang="pug">
Popup
    template(
      slot="title"
    )
      template(
        v-if="isEdit"
      ) {{ $t('SupplierGroups.popupForm_Title_Edit') }}

      template(
        v-else
      ) {{ $t('SupplierGroups.headerNewButton') }}

    template(slot="content")

      form.Form(
        id              ="form-supplier-groups"
        @submit.prevent ="onSubmitSupplierGroupForm"
      )

        div.Form-item.required
          label.Form-item-label {{ $t('SupplierGroups.popupForm_Field_Name') }}
          .control
            customTextInput(
              name="name"
              ref="firstField"
              :error="veeErrors.has('name')"
              :data-vv-as="$t('SupplierGroups.popupForm_Field_Name')"
              v-validate="'required|max:128|verify_name'"
              id="input-supplier-groups-popup-field-name"
              v-model="form.name"
              :isDelayInput="true"
            )

            Button(
              size      ="medium"
              variant   ="icon loading",
              id        ="btn-supplier-groups-popup-loading-name",
              tabindex  ="-1",
              v-show    ="isPendingName"
            )
              Loading(theme="disable")

            Button(
              size      ="medium"
              variant   ="icon check",
              id        ="btn-recipes-popup-ok-name",
              tabindex  ="-1",
              v-show    ="isValidateName"
              iconName  ="icon-check"
              iconClass ="icon-check"
              :justIcon ="true"
            )

            showValidateError(
              v-show="veeErrors.has('name')"
              :errorName="veeErrors.first('name')"
            )

        div.Form-item
          label.Form-item-label(id="label-supplier-group-form-tax-number") {{ $t('Supplier.popup_FormField_TaxNumber') }}
          .control
            customTextInput(
              name="taxNumber"
              :data-vv-as="$t('Supplier.popup_FormField_TaxNumber')"
              id="input-supplier-group-form-tax-number"
              v-model="form.taxNumber"
              v-validate="'max:11'"
              :error="veeErrors.has('taxNumber')"
              :isDelayInput="true"
            )
            showValidateError(
              v-show="veeErrors.has('taxNumber')"
              :errorName="veeErrors.first('taxNumber')"
            )

        div.Form-item
          label.Form-item-label {{ $t('SupplierGroups.popupForm_Field_Info') }}
          .control
            textarea.txt(
              name          ="description"
              autocomplete  ="off"
              :data-vv-as   ="$t('SupplierGroups.popupForm_Field_Info')"
              v-validate    ="'max:512'",
              v-model       ="form.description"
              :class        ="{ 'is-danger': veeErrors.has('description') }"
              rows          ="5"
              type          ="text"
            )

          p.Form-item-help.is-danger(
            v-show="veeErrors.has('description')"
          ) {{ veeErrors.first('description') }}

    template(slot="footer")
      Button(
        primary
        size      ="medium"
        variant   ="full",
        id        ="btn-supplier-groups-popup-submit",
        form      ="form-supplier-groups",
        type      ="submit"
        :disabled ="isLoading",
        :isLoading="isLoading"
      )
        span(v-show="!isLoading")
          template(v-if="isEdit") {{ $t('SupplierGroups.popupForm_Title_Edit') }}
          template(v-else) {{ $t('SupplierGroups.headerNewButton') }}
</template>

<script>
import { mapFields, Validator } from 'vee-validate'
import { mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'SupplierGroups',
  data () {
    return {
      form: {
        name: null,
        description: null,
        taxNumber: null
      }
    }
  },
  props: {
    status: {
      type: String,
      default: 'new'
    }
  },

  created () {
    const self = this

    const verifyName = {
      getMessage (field, params, data) {
        return (data && data.message) || 'Something wrong'
      },
      validate (value) {
        return new Promise(resolve => {
          let params = {
            Name: value
          }
          if (self.isEdit) {
            params.Id = self.form.id
          }
          self.checkSupplierGroupName(params)
            .then(res => {
              if (res) {
                resolve({
                  valid: res.data.isAvailable
                })
              } else {
                resolve({
                  valid: false
                })
              }
            })
        })
      }
    }

    const verifyNameMessages = {
      tr: {
        messages: {
          verify_name: field => this.$t('SupplierGroups.form_validate_name')
        }
      }
    }

    Validator.extend('verify_name', verifyName)
    Validator.localize(verifyNameMessages)
  },

  computed: {
    isEdit () {
      return this.status === 'edit'
    },
    ...mapFields({
      flagsName: 'name'
    }),
    isPendingName () {
      return this.flagsName.pending
    },

    isValidateName () {
      if (this.flagsName.required) {
        return this.flagsName.valid && !this.isPendingName
      } else {
        return this.flagsName.valid && this.form.name !== '' && this.form.name !== null && !this.isPendingName
      }
    },
    isLoading () {
      return this.$wait.is(['SupplierGroup/updateSupplierGroup', 'SupplierGroup/createSupplierGroup', 'supplierFormGroupsSubmit'])
    }
  },
  methods: {
    ...mapActions('SupplierGroups', [
      'createSupplierGroups',
      'updateSupplierGroups',
      'getSupplierGroup',
      'checkSupplierGroupName'
    ]),

    ...mapActions('Notify', [
      'notifyShow'
    ]),

    formFocus () {
      this.$refs.firstField.focusCustomInput()
    },
    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },
    async newSupplierGroups () {
      const res = await this.createSupplierGroups(this.form)
      if (res) {
        const message = this.$t('Global.notification_Created', { name: this.form.name })
        this.notifyShow({ message })
        this.close()
      }
    },
    async EditSupplierGroups () {
      const res = this.updateSupplierGroups(this.form)
      if (res) {
        const message = this.$t('Global.notification_Updated', { name: this.form.name })
        this.notifyShow({ message })
        this.close()
      }
    },
    onSubmitSupplierGroupForm: vueWaitLoader(async function () {
      await this.$validator.validateAll().then(async result => {
        if (!result) return
        if (this.isEdit) return this.EditSupplierGroups()
        return this.newSupplierGroups()
      })
    }, 'supplierFormGroupsSubmit')
  },
  async mounted () {
    this.formFocus()
    if (this.isEdit) {
      const res = await this.getSupplierGroup(this.$route.params.id)
      if (res) {
        this.form = res.data.supplierGroup
      }
    }
  }
}
</script>

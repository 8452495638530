<template lang="pug">
DeleteAlert(
  :item="item",
  :fields="fields",
  :tableName="$t('Supplier.remove_TableName')",
  loading="Supplier/deleteSupplier",
  @delegateOnAlertRemoveOk="itemRemove")
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import getFields from '../fields'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'SupplierRemove',
  data () {
    return {
      fields: getFields(this.$t),
      item: {}
    }
  },
  async mounted () {
    if (this.$route.params.item) {
      this.item = this.$route.params.item
    } else {
      this.getSupplier(this.$route.params.id).then(res => {
        if (res.status === 200) {
          this.item = res.data.supplier
        }
      })
    }
  },
  computed: {
    ...mapGetters('Supplier', [
      'suppliersListPagination'
    ])
  },
  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),
    ...mapActions('Supplier', [
      'getSuppliers',
      'deleteSupplier',
      'getSupplier'
    ]),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },
    itemRemove: vueWaitLoader(async function () {
      await this.deleteSupplier(this.item.id).then(async res => {
        if (res) {
          const message = this.$t('Global.notification_Deleted', { name: this.item.name })
          this.notifyShow({ message })
          this.getSuppliers({pageNumber: this.suppliersListPagination.number, isActive: true})
          this.close()
        }
      })
    }, 'supplierHeaderRemove')
  }
}
</script>

<style lang="scss">
</style>

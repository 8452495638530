<template lang="pug">
div
  span(v-if="control") {{ $t('Dictionary.Yes') }}
  span(v-else) {{ $t('Dictionary.No') }}
</template>

<script>

export default {
  name: 'ShowBox',
  props: {
    control: {
      type: Boolean,
      required: true
    }
  }
}
</script>

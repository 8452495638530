import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'

export default {
  namespaced: true,

  state: {
    supplierGroups: []
  },
  getters: {
    supplierGroups: state => state.supplierGroups,
    isEmptySupplierGroups: state => state.supplierGroups.length === 0
  },

  actions: {
    async getSupplierGroups({ commit }) {
      const loadingName = 'SupplierGrops/getSupplierGroups'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('SupplierGroup/all')
        },
        success: result => {
          commit('SET_SUPPLIER_GROUPS', result.data.supplierGroupList)
          return result
        }
      })
      return results
    },
    async getSupplierGroup({ commit }, Id) {
      const loadingName = 'SupplierGrops/getSupplierGroup'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('SupplierGroup', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },
    async createSupplierGroups({ commit, dispatch }, item) {
      const loadingName = 'SupplierGroup/createSupplierGroup'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('SupplierGroup', item)
        },
        success: result => {
          dispatch('getSupplierGroups')
          return result
        }
      })
      return results
    },
    async updateSupplierGroups({ commit, dispatch }, item) {
      const loadingName = 'SupplierGroup/updateSupplierGroup'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.put('SupplierGroup', item)
        },
        success: result => {
          dispatch('getSupplierGroups')
          return result
        }
      })
      return results
    },
    async deleteSupplierGroup({ state, commit, dispatch }, Id) {
      const loadingName = 'Supplier/deleteSupplierGroup'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.delete('SupplierGroup', {
            params: { Id }
          })
        },
        success: result => {
          dispatch('getSupplierGroups')
          return result
        }
      })
      return results
    },

    async checkSupplierGroupName({ commit, dispatch }, params) {
      const loadingName = 'checkSupplierGroupName'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('SupplierGroup/check/name', { params })
        },
        success: result => result
      })
      return results
    }
  },
  mutations: {
    SET_SUPPLIER_GROUPS(state, list) {
      state.supplierGroups = list
    }
  }
}

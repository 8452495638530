import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import { Suppliers, MiniSuppliersList } from '../model'
import { Page, PageGetters, getPaginationNumber } from '@/utils/pagination'

export default {
  namespaced: true,

  state: {
    Suppliers: new Suppliers(),
    miniSuppliers: new MiniSuppliersList(),
    activeMiniSuppliers: new MiniSuppliersList(),
    pageHeaderLinks: [
      'Supplier',
      'SupplierGroups'
    ],
    suppliersListPagination: {
      ...Page
    }
  },

  getters: {
    pageHeaderLinks: state => state.pageHeaderLinks,
    Suppliers: state => state.Suppliers.list,
    MiniSuppliers: state => state.miniSuppliers.list,
    ActiveMiniSuppliers: state => state.activeMiniSuppliers.list,
    getSupplierItem: state => id => state.Suppliers.findItem(id),
    isEmpty: state => state.Suppliers.length === 0,
    suppliersListPagination: state => PageGetters({ state, stateName: 'suppliersListPagination' })
  },

  actions: {
    async getSuppliers({ state, commit, dispatch }, params) {
      params.pageNumber = getPaginationNumber({ selectedPage: params.pageNumber, currentPage: state.suppliersListPagination.number })
      const results = await requestHandler({
        title: 'getSuppliers',
        methodName: 'getSuppliers',
        method: () => {
          return HTTP.get('Supplier/all', { params })
        },
        success: result => {
          commit('UPDATE_SUPPLIER_LIST', result.data)
          return result
        }
      })
      return results
    },

    async getMiniSuppliersList({ commit }) {
      const loadingName = 'Supplier/getMiniSuppliersList'
      let URL = 'Supplier/all?PageSize=9999&&PageNumber=1'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get(URL)
        },
        success: result => {
          commit('UPDATE_MINI_SUPPLIER_LIST', result.data)
          commit('UPDATE_ACTIVE_MINI_SUPPLIER_LIST', result.data)
          return result
        }
      })
      return results
    },

    async getSupplier({ state, commit, dispatch }, Id) {
      const loadingName = 'Supplier/getSupplier'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('Supplier', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },

    async createSupplier({ commit, dispatch }, item) {
      const loadingName = 'Supplier/createSupplier'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('Supplier', item)
        },
        success: result => result
      })
      return results
    },

    async updateSupplier({ commit, dispatch }, item) {
      const loadingName = 'Supplier/updateSupplier'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.put('Supplier', item)
        },
        success: result => result
      })
      return results
    },

    async deleteSupplier({ state, commit, dispatch }, Id) {
      const loadingName = 'Supplier/deleteSupplier'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.delete('Supplier', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },

    async searchSupplier({ state, commit, dispatch }, Name) {
      const loadingName = 'Supplier/searchSupplier'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('Supplier/searchby/name', {
            params: { Name }
          })
        },
        success: result => result
      })
      return results
    },

    async searchSupplierWithPermission({ state, commit, dispatch }, Name) {
      const loadingName = 'Supplier/searchSupplierPermitted'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('Supplier/searchby/permitted/name', {
            params: { Name }
          })
        },
        success: result => result
      })
      return results
    },

    async checkSupplierName({ commit, dispatch }, params) {
      const loadingName = 'checkSupplierName'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('Supplier/check/name', params)
        },
        success: result => result
      })
      return results
    },

    async updateStoreCodes({ commit, dispatch }, payload) {
      const loadingName = 'updateStoreCodes'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('Supplier/updatestorecode', payload)
        },
        success: result => result
      })
      return results
    },

    async updateUnitCodes({ commit, dispatch }, payload) {
      const loadingName = 'updateStoreCodes'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('Supplier/updateunitcode', payload)
        },
        success: result => result
      })
      return results
    },

    async checkSupplierEmail({ commit, dispatch }, params) {
      const loadingName = 'checkSupplierEmail'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('Supplier/check/emailaddress', { params })
        },
        success: result => result
      })
      return results
    },

    async getSupplierStoreCodes({ commit, dispatch }, params) {
      const loadingName = 'getSupplierStoreCodes'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('Supplier/storecodes', { params })
        },
        success: result => result
      })
      return results
    },
    async getSupplierUnitCodes({ commit, dispatch }, params) {
      const loadingName = 'getSupplierUnitCodes'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('Supplier/unitcodes', { params })
        },
        success: result => result
      })
      return results
    },
    async getSupplierEInvoiceConfig({ commit, dispatch }, params) {
      const loadingName = 'getSupplierEInvoiceConfig'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('Supplier/einvoicesettings', { params })
        },
        success: result => result
      })
      return results
    },
    async updateSupplierEInvoiceConfig({ commit, dispatch }, payload) {
      const loadingName = 'UpdateSupplierEInvoiceConfig'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.put('Supplier/einvoicesettings', payload)
        },
        success: result => result
      })
      return results
    }
  },

  mutations: {
    RESET(state) {
      state.Suppliers = new Suppliers()
      state.suppliersListPagination.number = 1
      state.suppliersListPagination.size = 20
      state.suppliersListPagination.total = 0
    },

    UPDATE_SUPPLIER_LIST(state, data) {
      state.Suppliers.addItems(data.supplierList)
      state.Suppliers.list.map(item => {
        if (item.emailAddress) item.emailAddress = item.emailAddress.replace(/;/g, '; ')
      })
      state.suppliersListPagination.number = data.pageNumber
      state.suppliersListPagination.size = data.pageSize
      state.suppliersListPagination.total = data.totalCount
    },

    UPDATE_MINI_SUPPLIER_LIST(state, data) {
      state.miniSuppliers.addItems(data.supplierList)
    },

    UPDATE_ACTIVE_MINI_SUPPLIER_LIST(state, data) {
      const filteredSuppliers = data.supplierList.filter(item => item.isActive)
      state.activeMiniSuppliers.addItems(filteredSuppliers)
    },

    ADD_SUPPLIER_ITEM(state, item) {
      state.Suppliers.addItem(item)
      state.suppliersListPagination.total = state.totalCount + 1
    },

    UPDATE_SUPPLIER_ITEM(state, item) {
      state.Suppliers.updateItem(item)
    },

    DELETE_SUPPLIER_ITEM(state, Id) {
      state.Suppliers.deleteItem(Id)
      state.suppliersListPagination.total = state.totalCount - 1
    }
  }
}

<template lang="pug">
DeleteAlert(
  :item="item",
  :fields="fields",
  :tableName="$t('SupplierGroups.remove_TableName')",
  loading="Supplier/deleteSupplier",
  @delegateOnAlertRemoveOk="itemRemove")
</template>

<script>
import { mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'SupplierGroupsRemove',
  data () {
    return {
      fields: [
        {
          name: 'name',
          is_visible: true,
          short_title: this.$t('SupplierGroups.popupForm_Field_Name'),
          title: this.$t('SupplierGroups.popupForm_Field_Name')
        }
      ],
      item: {}
    }
  },
  async mounted () {
    const res = await this.getSupplierGroup(this.$route.params.id)
    if (res) {
      this.item = res.data.supplierGroup
    }
  },
  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),
    ...mapActions('SupplierGroups', [
      'getSupplierGroups',
      'getSupplierGroup',
      'deleteSupplierGroup'
    ]),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      await this.deleteSupplierGroup(this.item.id).then(async res => {
        if (res) {
          const message = this.$t('Global.notification_Deleted', { name: this.item.name })
          this.notifyShow({ message })
          this.getSupplierGroups()
          this.close()
        }
      })
    }, 'supplierRemoveGroups')

  }
}
</script>

<style lang="scss">
</style>

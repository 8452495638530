var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.control
      ? _c("span", [_vm._v(_vm._s(_vm.$t("Dictionary.Yes")))])
      : _c("span", [_vm._v(_vm._s(_vm.$t("Dictionary.No")))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template lang="pug">
DeleteAlert(
  :item="itemWithSupplier",
  :fields="fields",
  tableName="Ödemeyi",
  loading="Payment/deletePayment",
  @delegateOnAlertRemoveOk="itemRemove")
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'PaymentRemove',

  data () {
    return {
      fields: [
        {
          name: 'supplierName',
          is_visible: true,
          short_title: 'Tedarikçi',
          title: 'Tedarikçi'
        },
        {
          name: 'paymentDate',
          is_visible: true,
          short_title: 'Ödeme Tarihi',
          title: 'Ödeme Tarihi'
        },
        {
          name: 'amount',
          is_visible: true,
          short_title: 'Ödeme Tutarı',
          title: 'Ödeme Tutarı'
        }
      ],
      item: {}
    }
  },

  computed: {
    ...mapGetters('Supplier', [
      'getSupplierItem'
    ]),

    itemWithSupplier () {
      this.item = this.$route.params.item
      return Object.assign({}, this.item, {
        supplierName: this.getSupplierItem(this.item.supplierId).name
      })
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Payment', [
      'getAllPayment',
      'deletePayment'
    ]),

    close () {
      this.$router.go(-1)
    },

    itemRemove: vueWaitLoader(async function () {
      await this.deletePayment(this.item.id).then(async res => {
        if (res) {
          this.getAllPayment(this.item.id).then(res => {
            if (res) this.close()
          })
        }
      })
    }, 'supplierRemovePayment')
  }
}
</script>

<style lang="scss">
</style>

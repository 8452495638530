<template lang="pug">
transition(name="slide")
    .Popup
      .Popup-overlay(@click="close")
      .Popup-body

        .Popup-header
          .content
            h3.Popup-header-title
              | {{ item ? item.name : '' }}

            Button(
              id        ="btn-supplier-popup-close"
              variant   ="icon Popup-header-close"
              @click    ="close"
              iconName  ="icon-popup-close"
              iconClass ="icon-popup-close"
              :justIcon ="true"
            )

        .Popup-content
          div
            .Item-list
              .Item(
              v-for="(field, index) in fields",
              :key="index",
              v-if="item && item[field.name] !== 'null' && item[field.name] !== null && item[field.name] !== ''")
                h4.title {{ field.title }}
                p
                  template(v-if="field.type === 'phone'")
                    span.ff-mono {{ item[field.name] | formatPhone }}
                  template(v-else)
                    | {{item[field.name] }}

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import getFields from '../fields'

export default {
  name: 'SupplierView',

  data () {
    return {
      fields: getFields(this.$t),
      item: {
        name: ''
      }
    }
  },

  computed: {
    ...mapGetters('Supplier', [
      'getSupplierItem'
    ])

  },

  async mounted () {
    this.itemId = this.$route.params.id
    this.item = this.$route.params.item
  },

  methods: {
    ...mapActions('Supplier', [
      'getSuppliers'
    ]),
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    }
  }
}
</script>

<style lang="scss" scoped>

  // .fade-enter-active,
  // .fade-leave-active {
  //   transition: 0.3s;
  //   opacity: 0;
  // }

  // .fade-enter-to {
  //   opacity: 1;
  // }

  .Popup-header {
    .content {
      padding-bottom: 0;
    }
  }

  .Item-list {
    margin: -30px;
  }

  .Item {
    padding: 20px 30px;

    &:nth-child(even) {
      background-color: #f5fbff;
    }

    .title {
      font-weight: $font-weight-normal;
      margin-bottom: 10px;
      color: $color-text-light;
    }
  }
</style>

import Index from '../'
import SupplierGroups from '../supplier-groups'
import View from '../popup/view'
import Form from '../popup/form'
import FormSupplierGroups from '../popup/form-groups'
import Remove from '../popup/remove'
import RemoveGroups from '../popup/remove-groups'
import PaymentRemove from '../popup/remove-payment'

export default [
  {
    path: '/supplier',
    name: 'Supplier',
    component: Index,
    children: [
      {
        path: 'new',
        name: 'SupplierNew',
        component: Form
      },
      {
        path: 'item/:id/edit',
        name: 'SupplierEdit',
        component: Form,
        props: {
          status: 'edit'
        }
      },
      {
        path: 'item/:id',
        name: 'SupplierView',
        component: View
      },
      {
        path: 'item/:id/remove',
        name: 'SupplierRemove',
        component: Remove
      },
      {
        path: 'payment/:id/remove',
        name: 'PaymentRemove',
        component: PaymentRemove
      }
    ],
    meta: {
      main_menu: true,
      slug: 'supplier',
      page: 'supplier',
      category: 'Main_data',
      permissionKey: 'SuppliersMenu'
    }
  },
  {
    path: '/supplier/supplier-groups',
    name: 'SupplierGroups',
    component: SupplierGroups,
    meta: {
      permissionKey: 'SupplierGroupMenu',
      page: 'supplier'
    },
    children: [
      {
        name: 'NewSupplierGrops',
        path: 'new',
        component: FormSupplierGroups
      },
      {
        name: 'EditSupplierGrops',
        path: ':id/edit',
        component: FormSupplierGroups,
        props: {
          status: 'edit'
        }
      },
      {
        name: 'SupplierGroupRemove',
        path: ':id/remove',
        component: RemoveGroups
      }
    ]
  }
]

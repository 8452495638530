var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "slide" } }, [
    _c("div", { staticClass: "Popup" }, [
      _c("div", { staticClass: "Popup-overlay", on: { click: _vm.close } }),
      _c("div", { staticClass: "Popup-body" }, [
        _c("div", { staticClass: "Popup-header" }, [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("h3", { staticClass: "Popup-header-title" }, [
                _vm._v(_vm._s(_vm.item ? _vm.item.name : "")),
              ]),
              _c("Button", {
                attrs: {
                  id: "btn-supplier-popup-close",
                  variant: "icon Popup-header-close",
                  iconName: "icon-popup-close",
                  iconClass: "icon-popup-close",
                  justIcon: true,
                },
                on: { click: _vm.close },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "Popup-content" }, [
          _c("div", [
            _c(
              "div",
              { staticClass: "Item-list" },
              _vm._l(_vm.fields, function (field, index) {
                return _vm.item &&
                  _vm.item[field.name] !== "null" &&
                  _vm.item[field.name] !== null &&
                  _vm.item[field.name] !== ""
                  ? _c("div", { key: index, staticClass: "Item" }, [
                      _c("h4", { staticClass: "title" }, [
                        _vm._v(_vm._s(field.title)),
                      ]),
                      _c(
                        "p",
                        [
                          field.type === "phone"
                            ? [
                                _c("span", { staticClass: "ff-mono" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatPhone")(
                                        _vm.item[field.name]
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            : [_vm._v(_vm._s(_vm.item[field.name]))],
                        ],
                        2
                      ),
                    ])
                  : _vm._e()
              }),
              0
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c(
        "template",
        { slot: "title" },
        [
          _vm.isEdit
            ? [_vm._v(_vm._s(_vm.$t("SupplierGroups.popupForm_Title_Edit")))]
            : [_vm._v(_vm._s(_vm.$t("SupplierGroups.headerNewButton")))],
        ],
        2
      ),
      _c("template", { slot: "content" }, [
        _c(
          "form",
          {
            staticClass: "Form",
            attrs: { id: "form-supplier-groups" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmitSupplierGroupForm.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("SupplierGroups.popupForm_Field_Name"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customTextInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|max:128|verify_name",
                        expression: "'required|max:128|verify_name'",
                      },
                    ],
                    ref: "firstField",
                    attrs: {
                      name: "name",
                      error: _vm.veeErrors.has("name"),
                      "data-vv-as": _vm.$t(
                        "SupplierGroups.popupForm_Field_Name"
                      ),
                      id: "input-supplier-groups-popup-field-name",
                      isDelayInput: true,
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                  _c(
                    "Button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isPendingName,
                          expression: "isPendingName",
                        },
                      ],
                      attrs: {
                        size: "medium",
                        variant: "icon loading",
                        id: "btn-supplier-groups-popup-loading-name",
                        tabindex: "-1",
                      },
                    },
                    [_c("Loading", { attrs: { theme: "disable" } })],
                    1
                  ),
                  _c("Button", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isValidateName,
                        expression: "isValidateName",
                      },
                    ],
                    attrs: {
                      size: "medium",
                      variant: "icon check",
                      id: "btn-recipes-popup-ok-name",
                      tabindex: "-1",
                      iconName: "icon-check",
                      iconClass: "icon-check",
                      justIcon: true,
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("name"),
                        expression: "veeErrors.has('name')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("name") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item" }, [
              _c(
                "label",
                {
                  staticClass: "Form-item-label",
                  attrs: { id: "label-supplier-group-form-tax-number" },
                },
                [_vm._v(_vm._s(_vm.$t("Supplier.popup_FormField_TaxNumber")))]
              ),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customTextInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "max:11",
                        expression: "'max:11'",
                      },
                    ],
                    attrs: {
                      name: "taxNumber",
                      "data-vv-as": _vm.$t(
                        "Supplier.popup_FormField_TaxNumber"
                      ),
                      id: "input-supplier-group-form-tax-number",
                      error: _vm.veeErrors.has("taxNumber"),
                      isDelayInput: true,
                    },
                    model: {
                      value: _vm.form.taxNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "taxNumber", $$v)
                      },
                      expression: "form.taxNumber",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("taxNumber"),
                        expression: "veeErrors.has('taxNumber')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("taxNumber") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("SupplierGroups.popupForm_Field_Info"))),
              ]),
              _c("div", { staticClass: "control" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "max:512",
                      expression: "'max:512'",
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.description,
                      expression: "form.description",
                    },
                  ],
                  staticClass: "txt",
                  class: { "is-danger": _vm.veeErrors.has("description") },
                  attrs: {
                    name: "description",
                    autocomplete: "off",
                    "data-vv-as": _vm.$t("SupplierGroups.popupForm_Field_Info"),
                    rows: "5",
                    type: "text",
                  },
                  domProps: { value: _vm.form.description },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "description", $event.target.value)
                    },
                  },
                }),
              ]),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.veeErrors.has("description"),
                      expression: "veeErrors.has('description')",
                    },
                  ],
                  staticClass: "Form-item-help is-danger",
                },
                [_vm._v(_vm._s(_vm.veeErrors.first("description")))]
              ),
            ]),
          ]
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: {
                primary: "",
                size: "medium",
                variant: "full",
                id: "btn-supplier-groups-popup-submit",
                form: "form-supplier-groups",
                type: "submit",
                disabled: _vm.isLoading,
                isLoading: _vm.isLoading,
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isLoading,
                      expression: "!isLoading",
                    },
                  ],
                },
                [
                  _vm.isEdit
                    ? [
                        _vm._v(
                          _vm._s(_vm.$t("SupplierGroups.popupForm_Title_Edit"))
                        ),
                      ]
                    : [
                        _vm._v(
                          _vm._s(_vm.$t("SupplierGroups.headerNewButton"))
                        ),
                      ],
                ],
                2
              ),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
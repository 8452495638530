var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c(
        "template",
        { slot: "title" },
        [
          _vm.isEdit
            ? [
                _vm._v(_vm._s(_vm.$t("Supplier.popup_Title_Edit"))),
                _c("DualCustomRadio", {
                  attrs: { isActive: _vm.form.isActive },
                  on: { delegateIsActiveValue: _vm.changeRadioButton },
                }),
                !_vm.form.isActive
                  ? _c(
                      "div",
                      { staticClass: "passive-supplier-warning m-top-5" },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("Supplier.popup_Supplier_Warning"))
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            : [_vm._v(_vm._s(_vm.$t("Supplier.popup_Title_Add")))],
        ],
        2
      ),
      _c("template", { slot: "content" }, [
        _c(
          "form",
          {
            staticClass: "Form",
            attrs: { id: "form-supplier" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmitSupplierForm(_vm.isEdit)
              },
            },
          },
          [
            _c("div", { staticClass: "Form-item" }, [
              _c("div", { staticClass: "zero" }, [
                _c(
                  "div",
                  { staticClass: "zero-checkbox" },
                  [
                    _c("CustomCheckbox", {
                      attrs: {
                        id: "checkbox-supplier-isZeroPriceAvailable",
                        label: "",
                      },
                      model: {
                        value: _vm.form.isZeroPriceAvailable,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "isZeroPriceAvailable", $$v)
                        },
                        expression: "form.isZeroPriceAvailable",
                      },
                    }),
                  ],
                  1
                ),
                _c("span", { staticClass: "zero-span" }, [
                  _vm._v(
                    _vm._s(_vm.$t("Supplier.popup_Span_isZeroPriceAvailable"))
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "zero-checkbox m-left-15" },
                  [
                    _c("CustomCheckbox", {
                      attrs: {
                        id: "checkbox-supplier-isEInvoicePayer",
                        label: "",
                      },
                      model: {
                        value: _vm.form.isEInvoicePayer,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "isEInvoicePayer", $$v)
                        },
                        expression: "form.isEInvoicePayer",
                      },
                    }),
                  ],
                  1
                ),
                _c("span", { staticClass: "zero-span" }, [
                  _vm._v(_vm._s(_vm.$t("Supplier.popup_isEInvoiceUser"))),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Supplier.popup_FormField_Name_Short"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customTextInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|max:128|verify_name",
                        expression: "'required|max:128|verify_name'",
                      },
                    ],
                    ref: "firstField",
                    attrs: {
                      name: "name",
                      error: _vm.veeErrors.has("name"),
                      "data-vv-as": _vm.$t("Supplier.popup_FormField_Name"),
                      id: "input-supplier-form-field-name",
                      isDelayInput: true,
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                  _c(
                    "Button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isPendingName,
                          expression: "isPendingName",
                        },
                      ],
                      attrs: {
                        id: "btn-supplier-popup-loading-name",
                        tabindex: "-1",
                        variant: "icon loading",
                      },
                    },
                    [_c("Loading", { attrs: { theme: "disable" } })],
                    1
                  ),
                  _c("Button", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isValidateName,
                        expression: "isValidateName",
                      },
                    ],
                    attrs: {
                      id: "btn-supplier-popup-ok-name",
                      tabindex: "-1",
                      variant: "icon check",
                      iconName: "icon-check",
                      iconClass: "icon-check",
                      justIcon: true,
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("name"),
                        expression: "veeErrors.has('name')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("name") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t("Supplier.detail_Popup_FormField_SupplierGroup")
                  )
                ),
              ]),
              _c(
                "div",
                { staticClass: "control form-item-select" },
                [
                  _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                  _c("customSelectInput", {
                    attrs: {
                      name: "supplierGroups",
                      optionData: _vm.supplierGroups,
                      id: "select-supplier-popup-supplier-group",
                      optionIdName: "option-suppliers-popup-group-name",
                    },
                    model: {
                      value: _vm.form.supplierGroupId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "supplierGroupId", $$v)
                      },
                      expression: "form.supplierGroupId",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(
                  _vm._s(_vm.$t("Supplier.detail_Popup_FormField_Currency"))
                ),
              ]),
              _c(
                "div",
                { staticClass: "control form-item-select" },
                [
                  _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                  _c("customSelectInput", {
                    attrs: {
                      name: "currency",
                      optionName: "isoCode",
                      disabled: _vm.isEdit,
                      optionData: _vm.CurrencyList,
                      id: "input-group-index-form-item-hasMajorGroups",
                      optionIdName: "option-suppliers-popup-currency-name",
                    },
                    model: {
                      value: _vm.form.currencyId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "currencyId", $$v)
                      },
                      expression: "form.currencyId",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm.RoleArea === "local" && !_vm.isEdit
              ? _c(
                  "div",
                  { staticClass: "Form-item required" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "Form-item-label",
                        attrs: { id: "label-store-group" },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "Supplier.popupForm_Field_Permitted_StoreGroups"
                            )
                          )
                        ),
                      ]
                    ),
                    _c("CustomMultiSelectList", {
                      attrs: {
                        id: "custom-multiselect-list-store-group-supplier-form",
                        items: _vm.permittedStoreGroupList,
                        selected: _vm.form.storeGroupList,
                        isValid: _vm.errorForStoreGroups && !_vm.isLoading,
                        disablePopup: _vm.isSinglePermittedStoreGroup,
                      },
                      on: { delegateCheckItem: _vm.selectStoreGroup },
                    }),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errorForStoreGroups && !_vm.isLoading,
                            expression: "errorForStoreGroups && !isLoading",
                          },
                        ],
                        staticClass: "Form-item-help is-danger",
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "Supplier.popupForm_Error_Permitted_StoreGroups"
                            )
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showTaxNumberInput,
                    expression: "!showTaxNumberInput",
                  },
                ],
                staticClass: "Form-item",
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "Form-item-label",
                    attrs: { id: "label-supplier-form-brach-code" },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("Supplier.popup_FormField_branchCode"))
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "control" },
                  [
                    _c("customTextInput", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "max:50",
                          expression: "'max:50'",
                        },
                      ],
                      attrs: {
                        name: "branchCode",
                        "data-vv-as": _vm.$t(
                          "Supplier.popup_FormField_branchCode"
                        ),
                        id: "input-supplier-form-branch-code",
                        error: _vm.veeErrors.has("branchCode"),
                      },
                      model: {
                        value: _vm.form.supplierBranchCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "supplierBranchCode", $$v)
                        },
                        expression: "form.supplierBranchCode",
                      },
                    }),
                    _c("showValidateError", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.veeErrors.has("branchCode"),
                          expression: "veeErrors.has('branchCode')",
                        },
                      ],
                      attrs: { errorName: _vm.veeErrors.first("branchCode") },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showTaxNumberInput,
                    expression: "showTaxNumberInput",
                  },
                ],
                staticClass: "Form-item",
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "Form-item-label",
                    attrs: { id: "label-supplier-form-tax-number" },
                  },
                  [_vm._v(_vm._s(_vm.$t("Supplier.popup_FormField_TaxNumber")))]
                ),
                _c(
                  "div",
                  { staticClass: "control" },
                  [
                    _c("customTextInput", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "max:11",
                          expression: "'max:11'",
                        },
                      ],
                      attrs: {
                        name: "taxNumber",
                        "data-vv-as": _vm.$t(
                          "Supplier.popup_FormField_TaxNumber"
                        ),
                        id: "input-supplier-form-tax-number",
                        error: _vm.veeErrors.has("taxNumber"),
                      },
                      model: {
                        value: _vm.form.taxNumber,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "taxNumber", $$v)
                        },
                        expression: "form.taxNumber",
                      },
                    }),
                    _c("showValidateError", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.veeErrors.has("taxNumber"),
                          expression: "veeErrors.has('taxNumber')",
                        },
                      ],
                      attrs: { errorName: _vm.veeErrors.first("taxNumber") },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c("div", { staticClass: "Form-item" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Supplier.popup_FormField_deferredDay"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customNumberInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "min_value:1",
                        expression: "'min_value:1'",
                      },
                    ],
                    attrs: {
                      name: "deferredDay",
                      error: _vm.veeErrors.has("deferredDay"),
                      "data-vv-as": _vm.$t(
                        "Supplier.popup_FormField_deferredDay"
                      ),
                      id: "input-supplier-form-account-deferred-day",
                    },
                    model: {
                      value: _vm.form.deferredDay,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "deferredDay", $$v)
                      },
                      expression: "form.deferredDay",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("deferredDay"),
                        expression: "veeErrors.has('deferredDay')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("deferredDay") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Supplier.popup_FormField_Address"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.address,
                        expression: "form.address",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "max:512",
                        expression: "'max:512'",
                      },
                    ],
                    staticClass: "txt",
                    class: { "is-danger": _vm.veeErrors.has("address") },
                    attrs: {
                      name: "address",
                      rows: "2",
                      "data-vv-as": _vm.$t(
                        "Supplier.popup_FormField_Address_Short"
                      ),
                      type: "text",
                      id: "input-supplier-form-address-short",
                    },
                    domProps: { value: _vm.form.address },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "address", $event.target.value)
                      },
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("address"),
                        expression: "veeErrors.has('address')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("address") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Supplier.popup_FormField_EmailAddress"))),
              ]),
              _c("div", { staticClass: "control" }, [
                _c("div", { staticClass: "input-listing" }, [
                  _c(
                    "div",
                    { staticClass: "input-listing-add" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.emailInput,
                            expression: "emailInput",
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "email|verify_email",
                            expression: "'email|verify_email'",
                          },
                        ],
                        ref: "inputList",
                        staticClass: "txt",
                        class: {
                          "is-danger": _vm.veeErrors.has("emailAddress"),
                        },
                        attrs: {
                          autocomplete: "off",
                          name: "emailAddress",
                          type: "text",
                          id: "input-supplier-form-email-address",
                          "data-vv-delay": _vm.$getConst("INPUT_DELAY_TIME"),
                          "data-vv-scope": "emailScope",
                          "data-vv-as": _vm.$t(
                            "Supplier.popup_FormField_EmailAddress"
                          ),
                        },
                        domProps: { value: _vm.emailInput },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              ) &&
                              _vm._k(
                                $event.keyCode,
                                "submit",
                                undefined,
                                $event.key,
                                undefined
                              )
                            )
                              return null
                            $event.preventDefault()
                            return _vm.addItem.apply(null, arguments)
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.emailInput = $event.target.value
                          },
                        },
                      }),
                      _c("Button", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isPendingEmail,
                            expression: "!isPendingEmail",
                          },
                        ],
                        attrs: {
                          variant: "icon global",
                          id: "btn-input-listing-add",
                          iconName: "icon-global-add",
                          iconClass: "icon-global-add",
                        },
                        on: { click: _vm.addItem },
                      }),
                      _c(
                        "Button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isPendingEmail,
                              expression: "isPendingEmail",
                            },
                          ],
                          attrs: {
                            variant: "icon loading",
                            id: "btn-supplier-popup-loading-email",
                            tabindex: "-1",
                          },
                        },
                        [_c("Loading", { attrs: { theme: "disable" } })],
                        1
                      ),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("emailScope.emailAddress"),
                            expression:
                              "veeErrors.has('emailScope.emailAddress')",
                          },
                        ],
                        attrs: {
                          errorName: _vm.veeErrors.first(
                            "emailScope.emailAddress"
                          ),
                          id: "input-listing-add-error",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.emailList.length > 0
                    ? _c(
                        "div",
                        { staticClass: "input-listing-items" },
                        _vm._l(_vm.emailList, function (item, index) {
                          return _c(
                            "div",
                            { staticClass: "item-list" },
                            [
                              _c("span", { staticClass: "item" }, [
                                _vm._v(_vm._s(item)),
                              ]),
                              _c("Button", {
                                attrs: {
                                  variant: "icon close",
                                  id: `input-listing-remove-${index}`,
                                  iconName: "icon-popup-close-withCurrentColor",
                                  iconClass:
                                    "icon-popup-close-withCurrentColor",
                                  justIcon: true,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeItem(item)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "Form-item" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(
                  _vm._s(_vm.$t("Supplier.popup_FormField_ContactEmailAddress"))
                ),
              ]),
              _c("div", { staticClass: "control" }, [
                _c("div", { staticClass: "input-listing" }, [
                  _c(
                    "div",
                    { staticClass: "input-listing-add" },
                    [
                      _c("customTextInput", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "email|verify_email",
                            expression: "'email|verify_email'",
                          },
                        ],
                        ref: "inputList",
                        class: {
                          "is-danger": _vm.veeErrors.has("contactEmailAddress"),
                        },
                        attrs: {
                          name: "contactEmailAddress",
                          id: "input-supplier-form-contact-email-address",
                          "data-vv-delay": _vm.$getConst("INPUT_DELAY_TIME"),
                          "data-vv-scope": "emailScope",
                          "data-vv-as": _vm.$t(
                            "Supplier.popup_FormField_ContactEmailAddress"
                          ),
                        },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              ) &&
                              _vm._k(
                                $event.keyCode,
                                "submit",
                                undefined,
                                $event.key,
                                undefined
                              )
                            )
                              return null
                            $event.preventDefault()
                            return _vm.addContactEmail.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.contactEmailInput,
                          callback: function ($$v) {
                            _vm.contactEmailInput = $$v
                          },
                          expression: "contactEmailInput",
                        },
                      }),
                      _c("Button", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isPendingContactEmail,
                            expression: "!isPendingContactEmail",
                          },
                        ],
                        attrs: {
                          variant: "icon global",
                          id: "btn-input-listing-add",
                          iconName: "icon-global-add",
                          iconClass: "icon-global-add",
                        },
                        on: { click: _vm.addContactEmail },
                      }),
                      _c(
                        "Button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isPendingContactEmail,
                              expression: "isPendingContactEmail",
                            },
                          ],
                          attrs: {
                            variant: "icon loading",
                            id: "btn-supplier-popup-loading-email",
                            tabindex: "-1",
                          },
                        },
                        [_c("Loading", { attrs: { theme: "disable" } })],
                        1
                      ),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has(
                              "emailScope.contactEmailAddress"
                            ),
                            expression:
                              "veeErrors.has('emailScope.contactEmailAddress')",
                          },
                        ],
                        attrs: {
                          errorName: _vm.veeErrors.first(
                            "emailScope.contactEmailAddress"
                          ),
                          id: "input-listing-add-error",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.contactEmailList.length > 0
                    ? _c(
                        "div",
                        { staticClass: "input-listing-items" },
                        _vm._l(_vm.contactEmailList, function (item, index) {
                          return _c(
                            "div",
                            { staticClass: "item-list" },
                            [
                              _c("span", { staticClass: "item" }, [
                                _vm._v(_vm._s(item)),
                              ]),
                              _c("Button", {
                                attrs: {
                                  variant: "icon close",
                                  id: `input-listing-remove-${index}`,
                                  iconName: "icon-popup-close-withCurrentColor",
                                  iconClass:
                                    "icon-popup-close-withCurrentColor",
                                  justIcon: true,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeContactEmailItem(item)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "Form-item" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Supplier.popup_FormField_PhoneNumber"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customTextInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "max:50",
                        expression: "'max:50'",
                      },
                    ],
                    attrs: {
                      name: "phoneNumber",
                      "data-vv-as": _vm.$t(
                        "Supplier.popup_FormField_PhoneNumber"
                      ),
                      error: _vm.veeErrors.has("phoneNumber"),
                      id: "input-supplier-form-field-phoneNumber",
                    },
                    model: {
                      value: _vm.form.phoneNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "phoneNumber", $$v)
                      },
                      expression: "form.phoneNumber",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("phoneNumber"),
                        expression: "veeErrors.has('phoneNumber')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("phoneNumber") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Supplier.popup_FormField_ContactName"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customTextInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "max:64",
                        expression: "'max:64'",
                      },
                    ],
                    attrs: {
                      name: "contactName",
                      "data-vv-as": _vm.$t(
                        "Supplier.popup_FormField_ContactName"
                      ),
                      error: _vm.veeErrors.has("contactName"),
                      id: "input-supplier-form-contact-name",
                    },
                    model: {
                      value: _vm.form.contactName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "contactName", $$v)
                      },
                      expression: "form.contactName",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("contactName"),
                        expression: "veeErrors.has('contactName')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("contactName") },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _vm.checkPermission("SupplierUsageWithListIsNotRequiredEdit")
            ? _c(
                "div",
                { staticClass: "Form-item m-bottom-15" },
                [
                  _c("CustomCheckbox", {
                    attrs: {
                      id: "checkbox-supplier-usageWithListIsNotRequired",
                      label: _vm.$t(
                        "Supplier.popup_FormField_usageWithListIsNotRequired"
                      ),
                    },
                    model: {
                      value: _vm.form.usageWithListIsNotRequired,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "usageWithListIsNotRequired", $$v)
                      },
                      expression: "form.usageWithListIsNotRequired",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          !_vm.isEdit
            ? _c(
                "div",
                { staticClass: "add-other-items-checkbox-container" },
                [
                  _c("CustomCheckbox", {
                    attrs: {
                      id: "checkbox-supplier-save-and-new",
                      label: _vm.$t("Global.formSaveAndNewText", {
                        form: _vm.$t("Supplier.popup_FormName"),
                      }),
                    },
                    model: {
                      value: _vm.saveAndNew,
                      callback: function ($$v) {
                        _vm.saveAndNew = $$v
                      },
                      expression: "saveAndNew",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "Button",
            {
              attrs: {
                size: "medium",
                primary: "",
                variant: "full",
                id: "btn-supplier-popup-submit",
                type: "submit",
                form: "form-supplier",
                disabled: _vm.isLoading,
                isLoading: _vm.isLoading,
              },
            },
            [
              _c(
                "span",
                [
                  _vm.isEdit
                    ? [_vm._v(_vm._s(_vm.$t("Supplier.popup_Button_Edit")))]
                    : [_vm._v(_vm._s(_vm.$t("Supplier.popup_Button_Add")))],
                ],
                2
              ),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
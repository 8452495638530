import BaseClass from '@/utils/baseclass'

export class Suppliers extends BaseClass {
  addItem(item) {
    let newItem = new Supplier(item)
    this.list.push(newItem)
  }

  updateItem(item) {
    let findItem = this.findItem(item.id)
    if (findItem) {
      findItem.id = item.id
      findItem.name = item.name
      findItem.taxNumber = item.taxNumber
      findItem.accountCode = item.accountCode
      findItem.address = item.address
      findItem.emailAddress = item.emailAddress
      findItem.contactEmailAddress = item.contactEmailAddress
      findItem.phoneNumber = item.phoneNumber
      findItem.isEInvoicePayer = item.isEInvoicePayer
      findItem.contactName = item.contactName
      findItem.supplierBranchCode = item.supplierBranchCode
      findItem.usageWithListIsNotRequired = item.usageWithListIsNotRequired
    }
  }
}

class Supplier {
  constructor(item) {
    this.id = item.id
    this.name = item.name
    this.taxNumber = item.taxNumber
    this.accountCode = item.accountCode
    this.address = item.address
    this.emailAddress = item.emailAddress
    this.contactEmailAddress = item.contactEmailAddress
    this.phoneNumber = item.phoneNumber
    this.contactName = item.contactName
    this.storeGroupList = item.storeGroupList || []
    this.storeGroups = item.storeGroups
    this.isGlobal = item.isGlobal
    this.isActive = item.isActive
    this.isZeroPriceAvailable = item.isZeroPriceAvailable
    this.currencyId = item.currencyId
    this.currencyIsoCode = item.currencyIsoCode
    this.isEInvoicePayer = item.isEInvoicePayer
    this.supplierBranchCode = item.supplierBranchCode
    this.usageWithListIsNotRequired = item.usageWithListIsNotRequired
  }
}

export class MiniSuppliersList extends BaseClass {
  addItem(item) {
    let newItem = new MiniSupplier(item)
    this.list.push(newItem)
  }

  updateItem(item) {
    let findItem = this.findItem(item.id)
    if (findItem) {
      findItem.id = item.id
      findItem.name = item.name
    }
  }
}

class MiniSupplier {
  constructor(item) {
    this.id = item.id
    this.name = item.name
    this.storeGroupCode = [item.storeGroups.map(store => store.code || store.name)].join(', ')
    this.isActive = item.isActive
  }
}
